<template>
  <div class="main-content">
    <h3 class="marginBottom20">
      工作人员详情
    </h3>
    <avue-form
      ref="form"
      v-model="form"
      v-loading="loading"
      :option="formOption"
      @submit="submit"
    >
      <template slot="id">
        {{
          form.regionId.code
        }}
      </template>

      <template slot="workerUsername">
        {{ form.workerUsername }}
      </template>

      <template slot="age">
        {{ form.age }}
      </template>

      <template slot="sex">
        {{ form.sex == 1?'男': form.sex == 2?'女':'' }}
      </template>

      <template slot="workerPosition">
        {{ form.workerPosition }}
      </template>
      <template slot="workerPhone">
        {{
          form.workerPhone
        }}
      </template>

      <template slot="workerRegionId">
        {{
          form.regionUserId.name
        }}
      </template>

    </avue-form>

    <SelectLink
      :show="show"
      :defaultData="form.regionUserId"
      @change="handleSelectData"
      @close="handleSelectClose"
    />
    <UserLink
      :show="show1"
      :defaultData="form.regionId"
      @change="handleSelectData1"
      @close="handleSelectClose1"
    />
  </div>
</template>

<script>
import { whiteAdd, whiteUpdate, whiteView } from "@/api/worker/index";
import SelectLink from "./components/selectLink";
import UserLink from "./components/userLink";
export default {
  name: "create",
  components: {
    SelectLink,
    UserLink,
  },
  data() {
    return {
      type: this.$route.query.type,
      id: this.$route.query.id,
      loading: false,
      form: {
        title: "基本信息",
        regionId: {},
        regionUserId: {},
      },
      formOption: {
        submitText: "确定",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "",
            labelWidth: 20,
            type: "title",
            prop: "title",
            span: 24,
            styles: {
              fontSize: "16px",
              borderBottom: "1px solid #eee",
              paddingBottom: "10px",
            },
          },
          {
            label: "选择会员",
            prop: "id",
            span: 12,
            row: true,
            showWordLimit: true,
          },
          {
            label: "姓名",
            prop: "workerUsername",
            maxlength: 20,
            showWordLimit: true,
            span: 12,
            row: true,
            placeholder: "请输入姓名",
          },
          {
            label: "年龄",
            prop: "age",
            maxlength: 20,
            showWordLimit: true,
            span: 12,
            row: true,
            placeholder: "请输入年龄",
          },
          {
            label: "性别",
            prop: "sex",
            maxlength: 20,
            showWordLimit: true,
            span: 12,
            row: true,
            placeholder: "请输入性别",
          },
          {
            label: "职位",
            prop: "workerPosition",
            maxlength: 20,
            showWordLimit: true,
            span: 12,
            row: true,
            placeholder: "请输入职位",
          },
          {
            label: "手机号",
            prop: "workerPhone",
            maxlength: 11,
            showWordLimit: true,
            span: 12,
            row: true,
            placeholder: "请输入手机号",
          },

          {
            label: "选择区域",
            prop: "workerRegionId",
            span: 12,
            row: true,
            showWordLimit: true,
          },
        ],
      },
      show: false,
      selectData: {
        id: "",
      },
      show1: false,
    };
  },
  mounted() {
    this.$route.query.id && this.getView();
  },
  methods: {
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            if (value < 1) {
              this.form[t] = 1;
              e.target.value = 1;
              return;
            }
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    getView() {
      this.loading = true;
      whiteView({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.form = {
            ...res.data,
          };

          this.form.regionId = {
            id: res.data.id,
            code: res.data.code,
          };
          this.form.regionUserId = {
            id: res.data.workerRegionId,
            name: res.data.regionName,
          };
        } else {
          this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },
    submit(form, done) {
      let data = {
        id: this.form.regionId.id,
        workerUsername: this.form.workerUsername,
        workerPosition: this.form.workerPosition,
        age: this.form.age,
        sex: this.form.sex,
        workerPhone: this.form.workerPhone,
        workerRegionId: this.form.regionUserId.id,
      };

      if (this.id) {
        whiteUpdate(data)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("修改成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      } else {
        whiteAdd(data)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      }
    },
    handleClose() {
      this.$router.go(-1);
    },

    // 会员选择
    handleSelect1() {
      if (this.type === "create") {
        this.show1 = true;
      }
    },
    handleSelectData1(e) {
      this.form.regionId = e;
      this.form.id = e.id;
      this.handleSelectClose1();
    },

    handleSelectClose1() {
      this.show1 = false;
    },

    // 区域选择
    handleSelect() {
      this.show = true;
    },
    handleSelectData(e) {
      this.form.regionUserId = e;
      this.form.workerRegionId = e.id;
      this.handleSelectClose();
    },

    handleSelectClose() {
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
